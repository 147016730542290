import { genRandonString } from "helpers/random";
import { useCallback, useMemo, useState } from "react";
import ToastContainer from "./ToastContainer";
import ToastContext from "./ToastContext";

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const deleteToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const addToast = useCallback(
    (type, message) => {
      const id = genRandonString(10);
      setToasts((prevToasts) => [...prevToasts, { id, type, message }]);

      setTimeout(() => {
        deleteToast(id);
      }, 4500);
    },
    [setToasts, deleteToast]
  );

  const toastApi = useMemo(
    () => ({
      toast: addToast,
    }),
    [addToast]
  );

  return (
    <ToastContext.Provider value={toastApi}>
      {children}
      <ToastContainer toasts={toasts} deleteToast={deleteToast} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
